import React from "react";
// import Figure from "./Figure";
import MainImage from "./MainImage";
import ReactPlayer from "react-player";
// import InstagramEmbed from "react-instagram-embed";
// import LatexRenderer from "./Latex";
const BlockContent = require('@sanity/block-content-to-react')

const AuthorReference = ({ node }) => {
  if (node && node.author && node.author.name) {
    return <span>{node.author.name}</span>;
  }
  return <></>;
};

const BlockRenderer = props => {
  const {style = 'normal'} = props.node;
  // const {listItem = null} = props.node;

  // console.log(props.node)
  
  // if (listItem === 'bullet') {
  //   return <p className="text-5xl font-light text-purple">12 {props.children}</p>
  // }
  
  if (style === 'h1') {
    return <h2 className="text-3xl font-black underline leading-none">{props.children}</h2>
  }  
  if (style === 'h2') {
    return <h2 className="text-2xl font-black leading-none">{props.children}</h2>
  }  
  if (style === 'h3') {
    return <h2 className="text-xl font-bold leading-none">{props.children}</h2>
  }  
  if (style === 'normal') {
    return <p className="pt-2 pb-6 leading-relaxed">{props.children}</p>
  }  
  if (style === 'blockquote') {
    return <blockquote className="text-xl font-light pl-3 py-3">- {props.children}</blockquote>
  }
  if (style === 'large') {
    return <p className="text-5xl font-light text-purple">{props.children}</p>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const link = props => {
  return (
    <a href={props.mark.href} className="text-blue hover:text-primary" >
      {props.children}
    </a>
  )
}

const serializers = {
  types: {
    block: BlockRenderer,
    authorReference: AuthorReference,
    mainImage: ({ node }) => <MainImage mainImage={node} />,
    videoEmbed: ({ node }) => <div style={{}}><ReactPlayer width="100%" height="400px" style={{}} url={node.url} controls={false} playing={true} loop muted config={{ youtube: { playerVars: { showinfo: 0, modestbranding: 1 }, embedOptions: {} } } } /></div>,
    // instagram: ({ node }) => {
    //   if (!node.url) return null;
    //   return <InstagramEmbed url={node.url} className="container mx-auto mt-6 mb-6" />;
    // },
    // math: ({ node, isInline = false }) => <LatexRenderer isInline={isInline} latex={node.latex} />,
  },
  marks: {
    link
  },
  // listItem: ({node}) => <li className="list-inside list-disc">{node.children[0].text}</li>
  // listItem: ({node}) => { console.log(node.children[0]); return <li className="list-inside list-disc">{node.children[0]}</li>}

  // listItem: ({node}) => <li className="list-inside list-disc"><BlockRenderer style={{}}>{node.children[0]}</BlockRenderer></li>
  
};

export default serializers;
